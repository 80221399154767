import CourseItem from '../courseItem/CourseItem';

import './courses.scss';

export default function Courses({ courses }) {
	const renderCourseList = () => {
		return (
			courses &&
			courses.map(course => {
				let { id, name, description, slug } = course;
				return (
					<CourseItem
						key={id}
						slug={slug}
						name={name}
						description={description}
					/>
				);
			})
		);
	};
	return (
		<section className='courses container' id='courses'>
			<div className='courses__heading'>
				<h3 className='title'>Kurslarimiz bilan tanishing</h3>
				<p className='descr'>
					Eng so'nggi va zamonaviy sohalarni biz bilan birga qulay muhitda
					o'rganing!
				</p>
			</div>
			<div className='courses__list'>
				<ul>{renderCourseList()}</ul>
			</div>
		</section>
	);
}
