import { useEffect, useState } from 'react';
import OWAService from '../../services/OWAService';

import './partners.scss';

export default function Partners() {
	let [partnersList, setPartnersList] = useState([]);
	let owaService = new OWAService();

	useEffect(() => {
		owaService.getPartners().then(setPartnersList);
	}, []);
	return (
		<section className='partners' id='partners'>
			<div className='partners__heading container'>
				<h3 className='title'>Hamkorlarimiz</h3>
				<p className='descr'>
					Biz ish bozoridagi eng yaxshi vakansiyalarni to'playmiz, intervyularga
					tayyorgarlik ko'rishga ko'maklashamiz va sizni hamkor kompaniyalarga
					tavsiya qilamiz
				</p>
			</div>
			<div className='partners__list container'>
				{partnersList?.map(partner => {
					let { id, first_name, image, link } = partner;
					return (
						<div key={id} className='partners__list__item'>
							<a href={link}>
								<img src={image} alt={first_name} />
							</a>
						</div>
					);
				})}
			</div>
		</section>
	);
}
