export default class OWAService {
  _apiBase = 'https://api.owa.uz';

  getResource = async url => {
    let res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Could not fetch ${url}, status: ${res.status}`);
    }

    return await res.json();
  };

  postForm = async paramsBody => {
    let { name, phone, comment } = paramsBody;
    let res = await fetch(
      `https://fastapi.modme.uz/api/v1/leads/lead?${new URLSearchParams({
        section: 'web-sayt',
        name,
        phone,
        branch_id: 3457,
      })}&comment=${comment}`,
      {
        method: 'POST',
      }
    );

    if (!res.ok) {
      throw new Error(`Could not fetch, status: ${res.status}`);
    }

    return await res.json();
  };

  getAllCourses = async () => {
    const res = await this.getResource(`${this._apiBase}/courses`);
    return await res;
  };
  getTeam = async () => {
    const res = await this.getResource(`${this._apiBase}/team`);
    return await res;
  };
  getCourse = async id => {
    const res = await this.getResource(`${this._apiBase}/courses/${id}`);
    return await res;
  };
  getFaq = async (id = 0) => {
    const res = await this.getResource(`${this._apiBase}/faq/${id}`);
    return await res;
  };
  getPartners = async () => {
    const res = await this.getResource(`${this._apiBase}/partners`);
    return await res;
  };
  //
  // getPost = async id => {
  //     const res = await this.getResource(`${this._apiBase}/posts/${id}`)
  //     return await res.data.post[0]
  // }

  // _transformPost = char => {
  //     return {
  //         _id: char._id,
  //         title: char.title,
  //         description: char.description
  //             ? `${char.description.slice(0, 220)}...`
  //             : 'There is no description for this character',
  //         post: char.post,
  //         imgSrc: 'http://blogadmin.texnologiyait.uz' + char.files,
  //         postNumber: char.post_number,
  //         createdAt: char.createdAt,
  //         theme: char.theme.slice(0, 1).toUpperCase() + char.theme.slice(1),
  //     }
  // }
}
