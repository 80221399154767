import Header from '../components/header/Header';
import Promo from '../components/promo/Promo';
import Faq from '../components/faq/Faq';
// import Testimonials from "../components/testimonials/Testimonials";
import Contact from '../components/contact/Contact';
import Footer from '../components/footer/Footer';
import Module from '../components/module/Module';
import Loader from '../components/loader/Loader';

import { useParams } from 'react-router-dom';
import OWAService from '../services/OWAService';
import { useEffect, useState } from 'react';

// import CoursesService from "../services/CoursesService";

export default function CoursePage() {
	let owaService = new OWAService();
	let [loading, setLoading] = useState(true);
	let [courseInfo, setCourseInfo] = useState({});
	let { courseSlug } = useParams();

	useEffect(() => {
		owaService
			.getCourse(courseSlug)
			.then(setCourseInfo)
			.then(() => setLoading(false));
	}, [courseSlug]);

	let {
		id,
		name,
		description_full,
		total_price,
		monthly_price,
		discount,
		discount_percent,
		duration_month,
		duration_month_text,
		duration_per_week,
		duration_per_week_text,
		lessons_count,
		tags,
		modules,
	} = courseInfo;

	let content = loading ? (
		<Loader />
	) : (
		<>
			<Header slug={courseSlug} />
			<main>
				<Promo
					name={name}
					descriptionFull={description_full}
					btnText='Kursga yozilish'
					durationMonthText={duration_month_text}
					durationPerWeekText={duration_per_week_text}
					monthlyPrice={monthly_price}
					lessonsCount={lessons_count}
					coursePage={true}
				/>
				<Module
					totalPrice={total_price}
					monthlyPrice={monthly_price}
					modules={modules}
					durationMonth={duration_month}
				/>
				<Contact slug={courseSlug} coursePage={true} />
				<Faq id={id} />
				{/*<Testimonials/>*/}
			</main>
			<Footer />
		</>
	);

	return content;
}
