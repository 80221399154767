import { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

import OWAService from '../../services/OWAService';
import './contact.scss';

let clrTimeout = (cbTm, cbArg, tmId) => {
	cbTm(cbArg);
	clearTimeout(tmId);
};

export default function Contact({ slug, coursePage }) {
	let [nameValue, setNameValue] = useState('');
	let [phoneValue, setPhoneValue] = useState('+998(__)___-__-__');
	let [phoneValid, setPhoneValid] = useState(false);
	let [nameValid, setNameValid] = useState(false);
	let [requestStatus, setRequestStatus] = useState('Yuborish');
	let [buttonStatus, setButtonStatus] = useState(false);
	let [phoneStatus, setPhoneStatus] = useState(null);
	let [nameStatus, setNameStatus] = useState(null);

	let owaService = new OWAService();

	const onChangeValidPhoneNumber = e => {
		setPhoneValue(e.target.value);
	};

	const onChangeValidName = e => {
		setNameValue(e.target.value);
	};

	useEffect(() => {
		if (nameValue.length >= 3) {
			setNameValid(true);
		} else {
			setNameValid(false);
		}

		if (!phoneValue.includes('_')) {
			setPhoneValid(true);
		} else {
			setPhoneValid(false);
		}
		if (phoneValid && nameValid) {
			setButtonStatus(false);
		} else {
			setButtonStatus(true);
		}
	}, [nameValue, phoneValue, nameValid, phoneValid]);

	const sendForm = e => {
		e.preventDefault();
		let paramsData = { comment: slug, name: nameValue, phone: phoneValue };
		setRequestStatus('Yuborilmoqda... 🔄');
		if (phoneValid && nameValid) {
			owaService
				.postForm(paramsData)
				.then(() => {
					setNameStatus(null);
					setPhoneStatus(null);
				})
				.then(() => {
					setRequestStatus('Yuborildi ✅');
				})
				.then(() => {
					setNameValue('');
					setPhoneValue('+998(__)___-__-__');
				})
				.then(() => {
					let timer = setTimeout(() => {
						clrTimeout(setRequestStatus, 'Yuborish', timer);
					}, 2000);
				})
				.catch(() => {
					setRequestStatus("Ma'lumotlar xato kiritildi! 🚫");
					let timer = setTimeout(() => {
						clrTimeout(setRequestStatus, 'Yuborish', timer);
					}, 2000);
				});
		} else {
			if (!phoneValid) {
				setPhoneStatus('error');
				let timerId = setTimeout(() => {
					setPhoneStatus(null);
					clearTimeout(timerId);
				}, 1000);
			}

			if (!nameValid) {
				setNameStatus('error');
				let timerId = setTimeout(() => {
					setNameStatus(null);
					clearTimeout(timerId);
				}, 1000);
			}

			setRequestStatus("Ma'lumotlarni kiriting! ⌨️");
			let timer = setTimeout(() => {
				clrTimeout(setRequestStatus, 'Yuborish', timer);
			}, 2000);
		}
	};

	return (
		<section className='contact' id='form'>
			<div className='container'>
				<div className='contact__heading'>
					<h3 className='title'>
						{coursePage ? "Kursga ro'yxatdan o'tish" : 'Ko’proq ma’lumot olish'}
					</h3>
					<p className='descr'>
						{coursePage
							? "Sizga kursimiz darsliklari bilan tanishib chiqib, kursimizda o'qishni boshlamoqchi bo'lsangiz, kerakli ma'lumot to'ldiring va tez orada sotuv menejerlarimiz siz bilan aloqaga chiqishadi"
							: "Agar kurslarimiz haqida savollaringiz bo'lsa yoki nimani tanlashni bilmasangiz, raqamingizni qoldiring: barcha savollaringizga javob berish uchun biz sizga qo'ng'iroq qilamiz"}
					</p>
				</div>
				<form action='#' onSubmit={sendForm}>
					<input
						type='text'
						name='name'
						placeholder='Ism'
						value={nameValue}
						onChange={onChangeValidName}
						className={nameStatus}
					/>
					<InputMask
						type='tel'
						name='phone'
						mask='+\9\98(99)999-99-99'
						placeholder='Telefon raqami'
						value={phoneValue}
						onChange={onChangeValidPhoneNumber}
						className={phoneStatus}
					/>
					<button type='submit' className={buttonStatus ? 'disabled' : null}>
						{requestStatus}
					</button>
				</form>
			</div>
		</section>
	);
}

Contact.defaultProps = {
	slug: "Ma'lumot olish",
	coursePage: false,
};
