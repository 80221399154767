import React from 'react';

import addressImg from '../../resources/images/owa_address.jpg';
import './Address.scss';

function Address() {
	return (
		<section className='address' id='address'>
			<div className='container'>
				<div className='address__heading'>
					<h3 className='title'>Manzilimiz</h3>
					<p className='descr'>
						Open Web Academy 2021-yilning 5-mart kunida Qarshi shahridagi
						Yoshlar markazi joylashgan binoning 3-qavatida rasmiy faoliyatini
						boshlagan
					</p>
				</div>
				<div className='address__info'>
					<div className='address__info-list'>
						<div className='address__info-list__item btn'>
							<div className='address__info__title'>Manzil</div>
							<a
								href='https://www.google.com/maps/place/Open+Web+Academy/@38.8615263,65.7966493,17z/data=!3m1!4b1!4m5!3m4!1s0x3f4ea7fbdd2c7dcf:0xd615a6530b82e0d1!8m2!3d38.8615263!4d65.798838'
								className='address__info__link'
							>
								<span className='link_svg'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 48 48'
										width='15px'
										height='15px'
									>
										<path
											fill='#48b564'
											d='M35.76,26.36h0.01c0,0-3.77,5.53-6.94,9.64c-2.74,3.55-3.54,6.59-3.77,8.06	C24.97,44.6,24.53,45,24,45s-0.97-0.4-1.06-0.94c-0.23-1.47-1.03-4.51-3.77-8.06c-0.42-0.55-0.85-1.12-1.28-1.7L28.24,22l8.33-9.88	C37.49,14.05,38,16.21,38,18.5C38,21.4,37.17,24.09,35.76,26.36z'
										/>
										<path
											fill='#fcc60e'
											d='M28.24,22L17.89,34.3c-2.82-3.78-5.66-7.94-5.66-7.94h0.01c-0.3-0.48-0.57-0.97-0.8-1.48L19.76,15	c-0.79,0.95-1.26,2.17-1.26,3.5c0,3.04,2.46,5.5,5.5,5.5C25.71,24,27.24,23.22,28.24,22z'
										/>
										<path
											fill='#2c85eb'
											d='M28.4,4.74l-8.57,10.18L13.27,9.2C15.83,6.02,19.69,4,24,4C25.54,4,27.02,4.26,28.4,4.74z'
										/>
										<path
											fill='#ed5748'
											d='M19.83,14.92L19.76,15l-8.32,9.88C10.52,22.95,10,20.79,10,18.5c0-3.54,1.23-6.79,3.27-9.3	L19.83,14.92z'
										/>
										<path
											fill='#5695f6'
											d='M28.24,22c0.79-0.95,1.26-2.17,1.26-3.5c0-3.04-2.46-5.5-5.5-5.5c-1.71,0-3.24,0.78-4.24,2L28.4,4.74	c3.59,1.22,6.53,3.91,8.17,7.38L28.24,22z'
										/>
									</svg>
								</span>
								Google maps
							</a>
							<p className='address__info__txt'>
								Qarshi shahri, Islom Karimov ko'chasi, 288-uy
							</p>
						</div>
						<div className='address__info-list__item btn'>
							<span className='address__info__title'>Telefon</span>
							<span className='address__info__title'>Email</span>
							<p className='address__info__txt'>+998(75) 220 01 07</p>
							<p className='address__info__txt'>info@owa.uz</p>
						</div>
						<div className='address__info-list__item btn'>
							<span className='address__info__title'>Ish kunlari</span>
							<span className='address__info__title'>Vaqt</span>
							<p className='address__info__txt'>Dushanba-Shanba</p>
							<p className='address__info__txt'>09:00-21:00</p>
						</div>
					</div>
					<div className='address__img'>
						<img src={addressImg} alt='OWA' />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Address;
