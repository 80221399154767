import { Link as LinkRouter } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

import './header.scss';
import logo from '../../resources/images/owa.svg';
import { useState } from 'react';

export default function Header({ slug }) {
  let [active, setActive] = useState(false);
  let activeClass = active ? 'active' : null;
  return (
    <header>
      <div className='container'>
        <div className='header__logo'>
          <LinkRouter to='/'>
            <img src={logo} alt='' />
          </LinkRouter>
        </div>
        <nav className={activeClass}>
          <ul>
            <li>
              <LinkRouter to='/'>Bosh sahifa</LinkRouter>
            </li>
            <li>
              <LinkScroll to='courses' spy={true} offset={0} duration={500}>
                Kurslar
              </LinkScroll>
            </li>
            <li>
              <LinkScroll to='address' spy={true} offset={0} duration={500}>
                Manzilimiz
              </LinkScroll>
            </li>
            <li>
              <LinkScroll to='partners' spy={true} offset={0} duration={500}>
                Hamkorlarimiz
              </LinkScroll>
            </li>
          </ul>
          <div className='header__contact'>
            <div className='header__contact__icon'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.2477 16.11L16.9277 14.43C17.154 14.2066 17.4403 14.0536 17.7518 13.9897C18.0634 13.9258 18.3867 13.9537 18.6827 14.07L20.7302 14.8875C21.0293 15.0089 21.2858 15.2162 21.4673 15.4831C21.6488 15.75 21.7473 16.0647 21.7502 16.3875V20.1375C21.7485 20.3571 21.7023 20.5741 21.6145 20.7753C21.5267 20.9766 21.3991 21.158 21.2393 21.3087C21.0796 21.4594 20.891 21.5761 20.6849 21.652C20.4788 21.7278 20.2595 21.7612 20.0402 21.75C5.69272 20.8575 2.79772 8.70753 2.25022 4.05753C2.22481 3.82918 2.24803 3.59804 2.31836 3.37931C2.38869 3.16058 2.50453 2.95922 2.65827 2.78848C2.81201 2.61774 3.00016 2.48148 3.21034 2.38868C3.42052 2.29587 3.64797 2.24862 3.87772 2.25003H7.50022C7.82348 2.25099 8.13906 2.34863 8.40637 2.5304C8.67368 2.71217 8.8805 2.96976 9.00022 3.27003L9.81772 5.31753C9.93791 5.61233 9.96858 5.93601 9.90588 6.24814C9.84319 6.56027 9.68992 6.847 9.46522 7.07253L7.78522 8.75253C7.78522 8.75253 8.75272 15.3 15.2477 16.11Z'
                  fill='black'
                />
              </svg>
            </div>
            <div className='header__contact__text'>
              <p className='text'>Ma'lumot uchun</p>
              <p className='number'>+998(75) 220 01 07</p>
            </div>
          </div>
        </nav>
        <div className='header__left'>
          <LinkScroll href='#' to='form' spy={true} offset={0} duration={500}>
            Kursga yozilish
          </LinkScroll>
          <div
            className={`header__menu ${activeClass}`}
            onClick={() => setActive(active => !active)}
          >
            <div></div>
          </div>
        </div>
      </div>
    </header>
  );
}
