import OWAService from '../../services/OWAService'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import './teamSlides.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

export default function TeamSlides() {
	let owaService = new OWAService()

	let [team, setTeam] = useState([])

	useEffect(() => {
		owaService
			.getTeam()
			.then(setTeam)
			.then(() => {
				console.log(team)
			})
	}, [])

	const renderTeamItem = () => {
		return team.map(teamItem => {
			let { id, first_name, last_name, image, position } = teamItem
			return (
				<SwiperSlide key={id}>
					<figure className='slider__item'>
						<div className='slider__img'>
							<img src={image} alt={position} />
						</div>
						<figcaption className='slider__info'>
							<h4>{`${first_name} ${last_name}`}</h4>
							<p>{position}</p>
						</figcaption>
					</figure>
				</SwiperSlide>
			)
		})
	}
	let sliderList = renderTeamItem()
	return (
		<section className='team-slides' id='team'>
			<div className='team__heading container'>
				<h3 className='title'>Bizning jamoa</h3>
				<p className='descr'>
					Barcha jamoa a'zolarimiz o'z sohalarining mutaxassislari hisoblanadi.
					Dasturchilardan iborat jamoamiz yirik loyihalarni amalga oshirishgan
					va hozirda turli-xil proektlar bilan ishlab kelishmoqda
				</p>
			</div>
			<div className='slider__content'>
				<div className='swiper-prev swiper-button-prev'>
					<svg
						width='41'
						height='16'
						viewBox='0 0 41 16'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H41V7H1V9Z'
							fill='#121212'
						/>
					</svg>
				</div>
				<Swiper
					spaceBetween={50}
					slidesPerView={team.length < 4 ? team.length : 4}
					loop={false}
					modules={[Navigation]}
					navigation={{
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					}}
					className='mySwiper container'
				>
					{sliderList}
				</Swiper>
				<div className='swiper-next swiper-button-next'>
					<svg
						width='41'
						height='16'
						viewBox='0 0 41 16'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M40.7071 8.70711C41.0976 8.31658 41.0976 7.68342 40.7071 7.29289L34.3431 0.928932C33.9526 0.538408 33.3195 0.538408 32.9289 0.928932C32.5384 1.31946 32.5384 1.95262 32.9289 2.34315L38.5858 8L32.9289 13.6569C32.5384 14.0474 32.5384 14.6805 32.9289 15.0711C33.3195 15.4616 33.9526 15.4616 34.3431 15.0711L40.7071 8.70711ZM0 9H40V7H0V9Z'
							fill='#121212'
						/>
					</svg>
				</div>
			</div>
		</section>
	)
}
