import ModuleList from '../moduleList/ModuleList';
import './module.scss';

export default function Module({
	totalPrice,
	monthlyPrice,
	modules,
	durationMonth,
}) {
	let moduleList =
		modules &&
		modules.map((moduleList, moduleId) => {
			moduleList.active = moduleId === 0;
			let { id, name, position, lessons, active } = moduleList;
			return (
				<ModuleList
					key={id}
					active={active}
					name={name}
					modulePosition={position}
					lessons={lessons}
				/>
			);
		});

	return (
		<>
			<section className='module container'>{moduleList}</section>
			<section className='course__price container'>
				<p className='monthly'>
					Oylik to’laganda: {monthlyPrice?.toLocaleString()} so’m
				</p>
				<p className='total__price'>
					Kurs narxi: {(monthlyPrice * durationMonth)?.toLocaleString()}
				</p>
				<p className='discount'>
					*Bir marta to’laganda: {totalPrice?.toLocaleString()} so’m to’laysiz
				</p>
			</section>
		</>
	);
}
