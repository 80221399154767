import TeamItem from '../teamItem/TeamItem';

import OWAService from '../../services/OWAService';
import { useEffect, useState } from 'react';

import './team.scss';

export default function Team() {
  let owaService = new OWAService();

  let [team, setTeam] = useState([]);

  useEffect(() => {
    owaService.getTeam().then(setTeam);
  }, []);

  const renderTeamItem = () => {
    return team.map(teamItem => {
      let { id, first_name, last_name, image, comment, position } = teamItem;
      return (
        <TeamItem
          key={id}
          firstName={first_name}
          lastName={last_name}
          image={image}
          comment={comment}
          position={position}
        />
      );
    });
  };
  let teamList = renderTeamItem();
  return (
    <section className='team container' id='team'>
      <div className='team__heading'>
        <h3 className='title'>Bizning jamoa</h3>
        <p className='descr'>
          Barcha jamoa a'zolarimiz o'z sohalarining mutaxassislari hisoblanadi.
          Dasturchilardan iborat jamoamiz yirik loyihalarni amalga oshirishgan
          va hozirda turli-xil proektlar bilan ishlab kelishmoqda
        </p>
      </div>
      <div className='team__list'>
        <ul>{teamList}</ul>
      </div>
    </section>
  );
}
