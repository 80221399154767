import FaqList from '../faqList/FaqList';
import './Faq.scss';

export default function Faq({ id }) {
	return (
		<section className='faq container'>
			<div className='faq__heading'>
				<h3 className='title'>FAQ</h3>
				<p className='descr'>Eng ko’p beriladigan savollarga javoblar!</p>
			</div>
			<FaqList id={id} />
		</section>
	);
}
