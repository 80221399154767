import Home from '../../pages/Home';

import { Routes, Route } from 'react-router-dom';
import CoursePage from '../../pages/CoursePage';

import './App.scss';

function App() {
	let a = 5;
	return (
		<div className='wrapper'>
			<Routes>
				<Route path='/' element={<Home />}></Route>
				<Route
					path='/courses/:courseSlug'
					element={<CoursePage name={a} />}
				></Route>
			</Routes>
		</div>
	);
}

export default App;
