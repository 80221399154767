import React from 'react';
import './loader.scss';

function Loader() {
	return (
		<div className='loader'>
			<div className='loader__wrapper'>
				<div className='loader__items'>
					<div className='loader__item'></div>
					<div className='loader__item'></div>
					<div className='loader__item'></div>
					<div className='loader__item'></div>
				</div>
				<div className='loader__shadows'>
					<div className='loader__shadow'></div>
					<div className='loader__shadow'></div>
					<div className='loader__shadow'></div>
					<div className='loader__shadow'></div>
				</div>
			</div>
		</div>
	);
}

export default Loader;
