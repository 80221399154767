import React, { useEffect, useState } from 'react';
import OWAService from '../../services/OWAService';
import FaqListItem from '../faqListItem/FaqListItem';

function FaqList({ id }) {
	let [faq, setFaq] = useState([]);

	let owaService = new OWAService();

	useEffect(() => {
		owaService.getFaq(id).then(setFaq);
	}, []);

	let faqList = faq?.map((faqItem, faqId) => {
		let { id, question, answer, active } = faqItem;
		return (
			<FaqListItem
				active={active}
				key={id}
				question={question}
				answer={answer}
			/>
		);
	});
	return <ul className='faq__list'>{faqList}</ul>;
}

FaqList.defaultProps = {
	id: 0,
};

export default FaqList;
