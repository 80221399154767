import React, { useState } from 'react';

function ModuleList({ name, modulePosition, lessons, active }) {
	let [classActive, setClassActive] = useState(active);
	let lessonsList =
		lessons &&
		lessons.map(lesson => {
			let { id, name, position } = lesson;
			return (
				<ModuleListItem
					key={id}
					lessonCount={`${modulePosition}.${position}`}
					lessonName={name}
				/>
			);
		});
	let clazz = classActive
		? 'module__list__heading active'
		: 'module__list__heading';
	return (
		<ul className='module__list'>
			<li className={clazz} onClick={() => setClassActive(!classActive)}>
				{`${name}`}
				<div className='arrow'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className='ionicon'
						viewBox='0 0 512 512'
					>
						<path
							fill='none'
							stroke='currentColor'
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='48'
							d='M184 112l144 144-144 144'
						/>
					</svg>
				</div>
			</li>
			{lessonsList}
		</ul>
	);
}

function ModuleListItem({ lessonCount, lessonName }) {
	return (
		<li className='module__list__item'>
			<div className='list__numb'>{lessonCount}</div>
			<div className='list__text'>{lessonName}</div>
		</li>
	);
}

export default ModuleList;
