import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import '../testimonials/testimonials.scss';

import photo from '../../resources/images/img.jpg';
import azamat from '../../resources/images/students/azamat-ochilov.webp';
import shaxzod from '../../resources/images/students/shaxzod-shokirov.webp';
import javohir from '../../resources/images/students/javohir-hasanov.webp';
import dilxush from '../../resources/images/students/dilxush-nurmaxmatov.webp';
import islom from '../../resources/images/students/islombek-murodov.webp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Testimonials() {
  return (
    <section className='testimonials'>
      <div className='testimonials__heading container'>
        <h3 className='title'>Bitiruvchilarimiz</h3>
        <p className='descr'>
          Dasturlarimizning afzalliklari haqida cheksiz gapirishimiz mumkin.
          Lekin bu haqida talabalarimiz natijasi orqali bilib olishingiz mumkin.
        </p>
      </div>
      <div className='testimonials__slider'>
        <div className='swiper-prev swiper-button-prev'>
          <svg
            width='23'
            height='16'
            viewBox='0 0 23 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H23V7H1V9Z'
              fill='white'
            />
          </svg>
        </div>
        <Swiper
          spaceBetween={40}
          slidesPerView={2}
          slideToClickedSlide={true}
          loop={true}
          modules={[Navigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          breakpoints={{
            0: {
              slidesPerView: 1.1,
            },
            440: {
              slidesPerView: 1.3,
            },
            640: {
              slidesPerView: 1.5,
            },
            768: { slidesPerView: 2 },
            850: { slidesPerView: 2 },
            992: { slidesPerView: 2 },
            1024: {
              slidesPerView: 2,
            },
            1280: { slidesPerView: 2 },
          }}
          className='slider__wrapper container'
        >
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={dilxush} alt='Dilxush Nurmaxmatov' />
              </div>
              <div className='card__info'>
                <p className='company'>Open Web kompaniyasi</p>
                <p className='student-name'>Dilxush Nurmaxmatov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Sherzod Utaganov
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Back-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Back-end dasturchi (Junior)
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={javohir} alt='Javohir Hasanov' />
              </div>
              <div className='card__info'>
                <p className='company'>UzProgers kompaniyasi</p>
                <p className='student-name'>Javohir Hasanov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Ali Odiljonov
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Front-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Front-end dasturchi (Junior)
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={shaxzod} alt='Shaxzod Shokirov' />
              </div>
              <div className='card__info'>
                <p className='company'>Algorithm kompaniyasi</p>
                <p className='student-name'>Shaxzod Shokirov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Shaxzod Jumayev
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Front-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Front-end dasturchi (Junior)
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={azamat} alt='Azamat Ochilov' />
              </div>
              <div className='card__info'>
                <p className='company'>Open Web Academy kompaniyasi</p>
                <p className='student-name'>Azamat Ochilov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Ali Odiljonov
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Front-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Teacher assistant
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={islom} alt='Islom Murodov' />
              </div>
              <div className='card__info'>
                <p className='company'>For Each Soft kompaniyasi</p>
                <p className='student-name'>Islom Murodov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Ali Odiljonov
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Front-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Front-end dasturchi (Junior)
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='slider__card'>
            <div className='card__header'>
              <div className='card__img'>
                <img src={photo} alt='Lazizbek Shodmonov' />
              </div>
              <div className='card__info'>
                <p className='company'>For Each Soft kompaniyasi</p>
                <p className='student-name'>Lazizbek Shodmonov</p>
              </div>
            </div>
            <div className='card__body'>
              <div className='info__list'>
                <div className='list__title'>O'qituvchisi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2388)'>
                        <path
                          d='M12 21.75C10.0716 21.75 8.18657 21.1782 6.58319 20.1068C4.97982 19.0355 3.73013 17.5127 2.99218 15.7312C2.25422 13.9496 2.06114 11.9892 2.43735 10.0979C2.81355 8.20656 3.74215 6.46928 5.10571 5.10571C6.46928 3.74215 8.20656 2.81355 10.0979 2.43735C11.9892 2.06114 13.9496 2.25422 15.7312 2.99218C17.5127 3.73013 19.0355 4.97982 20.1068 6.58319C21.1782 8.18657 21.75 10.0716 21.75 12C21.75 14.5859 20.7228 17.0658 18.8943 18.8943C17.0658 20.7228 14.5859 21.75 12 21.75ZM12 3.75C10.3683 3.75 8.77326 4.23386 7.41655 5.14038C6.05984 6.0469 5.00242 7.33538 4.378 8.84287C3.75358 10.3504 3.5902 12.0092 3.90853 13.6095C4.22685 15.2098 5.01259 16.6799 6.16637 17.8336C7.32016 18.9874 8.79017 19.7732 10.3905 20.0915C11.9909 20.4098 13.6497 20.2464 15.1571 19.622C16.6646 18.9976 17.9531 17.9402 18.8596 16.5835C19.7661 15.2268 20.25 13.6317 20.25 12C20.25 9.81197 19.3808 7.71355 17.8336 6.16637C16.2865 4.6192 14.188 3.75 12 3.75Z'
                          fill='black'
                        />
                        <path
                          d='M12 12.75C11.2583 12.75 10.5333 12.5301 9.91661 12.118C9.29993 11.706 8.81928 11.1203 8.53545 10.4351C8.25162 9.74984 8.17736 8.99584 8.32206 8.26841C8.46675 7.54098 8.8239 6.8728 9.34835 6.34835C9.8728 5.8239 10.541 5.46675 11.2684 5.32206C11.9958 5.17736 12.7498 5.25162 13.4351 5.53545C14.1203 5.81928 14.706 6.29993 15.118 6.91661C15.5301 7.5333 15.75 8.25832 15.75 9C15.75 9.99456 15.3549 10.9484 14.6517 11.6517C13.9484 12.3549 12.9946 12.75 12 12.75ZM12 6.75C11.555 6.75 11.12 6.88196 10.75 7.1292C10.38 7.37643 10.0916 7.72783 9.92127 8.13896C9.75098 8.5501 9.70642 9.0025 9.79323 9.43895C9.88005 9.87541 10.0943 10.2763 10.409 10.591C10.7237 10.9057 11.1246 11.12 11.561 11.2068C11.9975 11.2936 12.4499 11.249 12.861 11.0787C13.2722 10.9084 13.6236 10.62 13.8708 10.25C14.118 9.88002 14.25 9.44501 14.25 9C14.25 8.40326 14.0129 7.83097 13.591 7.40901C13.169 6.98705 12.5967 6.75 12 6.75Z'
                          fill='black'
                        />
                        <path
                          d='M19.1624 18C19.0583 18.0007 18.9552 17.9798 18.8596 17.9384C18.7641 17.8971 18.6782 17.8364 18.6074 17.76C17.8113 16.8917 16.8437 16.198 15.7657 15.723C14.6877 15.2479 13.5229 15.0017 12.3449 15H11.6549C9.45903 14.9953 7.34753 15.8453 5.76738 17.37C5.62138 17.4905 5.43523 17.5513 5.24627 17.5403C5.05731 17.5292 4.87951 17.4471 4.74855 17.3105C4.61758 17.1738 4.54313 16.9927 4.54013 16.8034C4.53713 16.6142 4.60581 16.4308 4.73238 16.29C6.58929 14.4952 9.07237 13.4945 11.6549 13.5H12.3449C13.7295 13.5032 15.0986 13.7926 16.3661 14.3502C17.6335 14.9077 18.7719 15.7214 19.7099 16.74C19.809 16.8469 19.8748 16.9803 19.8993 17.1239C19.9238 17.2676 19.9059 17.4153 19.8478 17.5489C19.7898 17.6826 19.694 17.7964 19.5723 17.8765C19.4505 17.9566 19.3081 17.9996 19.1624 18Z'
                          fill='black'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2388'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Ali Odiljonov
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>O'qigan kursi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_1054_2383)'>
                        <path
                          d='M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V6H20V18ZM6 10H8V12H6V10ZM6 14H14V16H6V14ZM16 14H18V16H16V14ZM10 10H18V12H10V10Z'
                          fill='#121212'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1054_2383'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Front-end
                </div>
              </div>
              <div className='info__list'>
                <div className='list__title'>Lavozimi:</div>
                <div className='list__descr'>
                  <span>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M11.572 7.4184L13.2099 5.78062C13.4027 5.58769 13.6887 5.52121 13.9467 5.60986C14.2048 5.69835 14.3899 5.92635 14.4238 6.19693L15.4059 14.0542C15.4545 14.4453 15.1766 14.8029 14.7857 14.8517C14.3946 14.9003 14.0369 14.6224 13.9882 14.2315L13.1875 7.82324L12.077 8.93325C11.7981 9.21227 11.3459 9.21227 11.0671 8.93325L9.95655 7.82324L8.75262 17.456L11.572 20.2757L14.3914 17.456L14.3453 17.0887C14.2967 16.6976 14.5746 16.3399 14.9655 16.2912C15.3566 16.2425 15.7143 16.5204 15.763 16.9114L15.8523 17.6257C15.8796 17.8443 15.8045 18.0636 15.6484 18.2193L12.0769 21.7907C11.7981 22.0698 11.3459 22.0698 11.067 21.7907L7.49556 18.2193C7.33946 18.0637 7.26437 17.8443 7.29163 17.6257L8.72022 6.19693C8.75402 5.92636 8.93914 5.69835 9.19729 5.60986C9.45527 5.52121 9.7413 5.5877 9.93408 5.78062L11.572 7.4184Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M8.92411 5.78086C8.64509 6.05973 8.64509 6.5119 8.92411 6.79076L11.067 8.93365C11.3459 9.21267 11.798 9.21267 12.0769 8.93365L14.2198 6.79076C14.4988 6.5119 14.4988 6.05973 14.2198 5.78086L12.0769 3.63798C11.798 3.35896 11.3459 3.35896 11.067 3.63798L8.92411 5.78086ZM10.4391 6.28581L11.5719 7.41864L12.7048 6.28581L11.5719 5.15298L10.4391 6.28581Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.0927 6.89142L19.8071 3.31994C20.1415 3.11108 20.2432 2.66989 20.0343 2.33573C19.8254 2.00138 19.3843 1.89964 19.0501 2.10852L13.3357 5.68C13.0014 5.88887 12.8996 6.33005 13.1085 6.66422C13.3174 6.99857 13.7586 7.10031 14.0927 6.89142Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.8071 5.68L4.09273 2.10851C3.75854 1.89965 3.31737 2.00137 3.10851 2.33572C2.89965 2.66991 3.00137 3.11108 3.33572 3.31994L9.05009 6.89142C9.38428 7.10029 9.82545 6.99856 10.0343 6.66421C10.2432 6.33003 10.1414 5.88886 9.8071 5.68Z'
                        fill='black'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M14.869 13.2845C15.1179 13.141 15.2561 12.8625 15.2207 12.5774L14.8361 9.50018C14.8065 9.26278 14.6596 9.05597 14.4454 8.94948C14.2307 8.84266 13.9775 8.85063 13.77 8.97053L8.26089 12.1514C8.06701 12.2632 7.93707 12.4593 7.90916 12.6811L7.46446 16.2371C7.43098 16.5061 7.5528 16.771 7.7784 16.9208C8.00449 17.0708 8.29596 17.0797 8.53066 16.9444L14.869 13.2845ZM9.28278 13.2107L9.06068 14.9885L13.7444 12.2845L13.5523 10.7459L9.28278 13.2107Z'
                        fill='black'
                      />
                    </svg>
                  </span>
                  Front-end dasturchi (Trainee)
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='swiper-next swiper-button-next'>
          <svg
            width='23'
            height='16'
            viewBox='0 0 23 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M22.7071 8.70711C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928932C15.9526 0.538408 15.3195 0.538408 14.9289 0.928932C14.5384 1.31946 14.5384 1.95262 14.9289 2.34315L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.70711ZM0 9H22V7H0V9Z'
              fill='white'
            />
          </svg>
        </div>
      </div>
    </section>
  );
}
