export default function TeamItem({ lastName, firstName, image, position }) {
	return (
		<li className='list__item btn btn-primary'>
			<div className='item__img'>
				<img src={image} alt={position} />
			</div>
			<div className='item__info'>
				<p>{position}</p>
				<h4>{`${firstName} ${lastName}`}</h4>
			</div>
		</li>
	);
}
