import { Link as LinkScroll } from 'react-scroll';
import parse from 'html-react-parser';
import PromoInfo from '../promoInfo/PromoInfo';

import './promo.scss';

export default function Promo({
	name,
	descriptionFull,
	btnText,
	coursePage,
	...otherProps
}) {
	return (
		<section className='promo'>
			<div className='container'>
				<div className='promo__content'>
					<div className='promo__left'>
						<h1>{name}</h1>
						<p>{descriptionFull}</p>
						{!coursePage ? (
							<LinkScroll
								href='#'
								to='courses'
								spy={true}
								offset={50}
								duration={500}
							>
								<button className='btn btn-warning'>{btnText}</button>
							</LinkScroll>
						) : null}
					</div>
					<div className='promo__right'>
						<div className='promo__video'>
							<svg
								width='80'
								height='80'
								viewBox='0 0 80 80'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM59.531 37.4255C61.479 38.5897 61.479 41.4116 59.531 42.5758L34.8085 57.351C31.809 59.1436 28 56.9826 28 53.4882V26.5137C28 23.0194 31.809 20.8583 34.8084 22.6509L59.531 37.4255Z'
									fill='#F5F5F5'
								/>
							</svg>
						</div>
					</div>
				</div>
				<PromoInfo info={otherProps} coursePage={coursePage} />
			</div>
		</section>
	);
}

Promo.defaultProps = {
	name: parse('Birinchi qadamni <span>hoziroq</span> qo’ying'),
	descriptionFull:
		"IT sohasida eng talab yuqori bo'lgan yo'nalishlaridan o'zingizga mos kursni tanlang va sohaning mutaxassisiga aylaning",
	btnText: 'Kurslar bilan tanishish',
};
