import React, { useState } from 'react';

function FaqListItem({ question, answer, active }) {
	let [classActive, setClassActive] = useState(active);
	let clazz = classActive ? 'answer active' : 'answer';
	return (
		<li
			className={'faq__list__item'}
			onClick={() => setClassActive(!classActive)}
		>
			<div className={`question ${classActive ? 'active' : ''}`}>
				<h3>{question}</h3>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					className={`ionicon`}
					viewBox='0 0 512 512'
				>
					<path
						fill='none'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='48'
						d='M184 112l144 144-144 144'
					/>
				</svg>
			</div>
			<div className={clazz}>{answer}</div>
		</li>
	);
}

export default FaqListItem;
