import { Link } from 'react-router-dom';

export default function CourseItem({ slug, name, description }) {
	return (
		<Link to={`/courses/${slug}`} className='btn'>
			<li className='list__item'>
				<div className='course__icon'>
					<svg
						width='22'
						height='22'
						viewBox='0 0 22 22'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<g clipPath='url(#clip0_646_2284)'>
							<path
								d='M18.333 3.6665H3.66634C2.65801 3.6665 1.83301 4.4915 1.83301 5.49984V16.4998C1.83301 17.5082 2.65801 18.3332 3.66634 18.3332H18.333C19.3413 18.3332 20.1663 17.5082 20.1663 16.4998V5.49984C20.1663 4.4915 19.3413 3.6665 18.333 3.6665ZM18.333 16.4998H3.66634V5.49984H18.333V16.4998ZM5.49967 9.1665H7.33301V10.9998H5.49967V9.1665ZM5.49967 12.8332H12.833V14.6665H5.49967V12.8332ZM14.6663 12.8332H16.4997V14.6665H14.6663V12.8332ZM9.16634 9.1665H16.4997V10.9998H9.16634V9.1665Z'
								fill='#121212'
							/>
						</g>
						<defs>
							<clipPath id='clip0_646_2284'>
								<rect width='22' height='22' fill='white' />
							</clipPath>
						</defs>
					</svg>
				</div>
				<div className='course__info'>
					<h4>{name}</h4>
					<p>{description}</p>
				</div>
				<div className='course__link'>
					<svg
						width='10'
						height='10'
						viewBox='0 0 10 10'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M3.33333 0V1.33333H7.72667L0 9.06L0.94 10L8.66667 2.27333V6.66667H10V0H3.33333Z'
							fill='black'
						/>
					</svg>
				</div>
			</li>
		</Link>
	);
}
