import { useState, useEffect } from 'react';

import Header from '../components/header/Header';
import Promo from '../components/promo/Promo';
import Courses from '../components/courses/Courses';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';
import Partners from '../components/partners/Partners';
import Contact from '../components/contact/Contact';
import Faq from '../components/faq/Faq.jsx';
import Footer from '../components/footer/Footer';
import Address from '../components/address/Address';

import Loader from '../components/loader/Loader';
import TeamSlides from '../components/teamSlides/TeamSlides';

import OWAService from '../services/OWAService';

export default function Home() {
  let owaService = new OWAService();
  let [loading, setLoading] = useState(true);
  let [courses, setCourses] = useState([]);

  useEffect(() => {
    owaService
      .getAllCourses()
      .then(setCourses)
      .then(() => setLoading(false));
  }, []);
  let content = loading ? (
    <Loader />
  ) : (
    <>
      <Header />
      <main>
        <Promo coursePage={false} />
        <Team />
        <TeamSlides />
        <Courses courses={courses} />
        <Testimonials />
        <Address />
        <Partners />
        <Contact />
        <Faq />
      </main>
      <Footer />
    </>
  );
  return content;
}
