import './footer.scss';

export default function Footer() {
	return (
		<footer>
			<section className='footer__content container'>
				<div className='footer__logo'>
					<svg
						width='120'
						viewBox='0 0 144 60'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M19.2614 38.5229C8.64062 38.5229 0 29.8849 0 19.2614C0 8.64062 8.64062 0 19.2614 0C29.8823 0 38.5229 8.64062 38.5229 19.2614C38.5256 29.8849 29.8849 38.5229 19.2614 38.5229ZM19.2614 4.86219C11.3219 4.86219 4.85951 11.3219 4.85951 19.2641C4.85951 27.2063 11.3192 33.6634 19.2614 33.6634C27.2036 33.6634 33.6634 27.2036 33.6634 19.2641C33.6634 11.3246 27.2036 4.86219 19.2614 4.86219Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M114.755 1.19482H108.697L89.3337 34.6976L69.9465 1.19482H63.8909L72.6091 16.2978L61.9856 34.6976L42.6412 1.19482H36.5454L57.4071 37.3308H66.5454L75.649 21.5641L84.7525 37.3308H93.8909L114.755 1.19482Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M119.269 5.75463L121.902 1.19482L124.535 5.75463L140.142 32.7709L142.777 37.3308H137.554L133.888 30.9807L133.856 30.9995L121.905 10.3118L109.965 30.9834L109.96 30.9807L106.293 37.3308H101.03L103.666 32.7709L119.269 5.75463Z'
							fill='white'
						/>
						<path
							d='M3.30969 58.5831C2.35706 57.6385 1.88074 56.4665 1.88074 55.0589C1.88074 53.6541 2.35706 52.4766 3.30969 51.5347C4.26233 50.5901 5.4558 50.1191 6.88743 50.1191C8.32174 50.1191 9.51253 50.5901 10.4652 51.5347C11.4178 52.4793 11.8941 53.6541 11.8941 55.0589C11.8941 56.4665 11.4178 57.6412 10.4652 58.5831C9.51253 59.5278 8.31906 59.9987 6.88743 59.9987C5.4558 59.9987 4.26233 59.5278 3.30969 58.5831ZM4.43091 52.5489C3.79939 53.1884 3.48363 54.026 3.48363 55.0589C3.48363 56.0918 3.79939 56.9267 4.43091 57.569C5.06244 58.2112 5.88127 58.5296 6.88743 58.5296C7.88288 58.5296 8.70172 58.2085 9.3386 57.569C9.97547 56.9294 10.2939 56.0918 10.2939 55.0589C10.2939 54.026 9.97547 53.1911 9.3386 52.5489C8.70172 51.9067 7.88556 51.5882 6.88743 51.5882C5.88127 51.5882 5.06244 51.9093 4.43091 52.5489Z'
							fill='white'
						/>
						<path
							d='M13.3623 59.7321V50.3877H17.4351C18.5108 50.3877 19.3832 50.6874 20.0441 51.2895C20.7078 51.8889 21.0396 52.6355 21.0396 53.5266C21.0396 54.4177 20.7078 55.1616 20.0441 55.7637C19.3805 56.3657 18.5108 56.6655 17.4351 56.6655H14.8983V59.7374H13.3623V59.7321ZM14.8956 55.1937H17.4324C18.0827 55.1937 18.5777 55.0438 18.9202 54.7468C19.2628 54.4498 19.434 54.043 19.434 53.5266C19.434 53.0101 19.2628 52.6034 18.9202 52.3037C18.5777 52.0066 18.08 51.8568 17.4324 51.8568H14.8956V55.1937Z'
							fill='white'
						/>
						<path
							d='M22.3743 59.7321V50.3877H29.1176V51.8568H23.9103V54.2598H28.1168V55.7289H23.9103V58.2657H29.1176V59.7348H22.3743V59.7321Z'
							fill='white'
						/>
						<path
							d='M30.5173 59.7321V50.3877H32.7196L36.7924 57.7974H36.9262L36.7924 56.6628V50.3877H38.3284V59.7321H36.1234L32.0506 52.3224H31.9169L32.0506 53.457V59.7321H30.5173V59.7321Z'
							fill='white'
						/>
						<path
							d='M43.3336 50.3877H44.9365L46.5662 58.3968H46.7669L48.343 50.3877H50.6791L52.3221 58.3968H52.5228L54.0856 50.3877H55.6215L53.6868 59.7321H51.2169L49.6141 52.0548H49.4134L47.8774 59.7321H45.3406L43.3336 50.3877Z'
							fill='white'
						/>
						<path
							d='M56.8845 59.7321V50.3877H63.6279V51.8568H58.4205V54.2598H62.6271V55.7289H58.4205V58.2657H63.6279V59.7348H56.8845V59.7321Z'
							fill='white'
						/>
						<path
							d='M65.0277 59.7321V50.3877H69.633C70.5134 50.3877 71.2118 50.6178 71.7283 51.0754C72.2447 51.533 72.5016 52.1056 72.5016 52.7907C72.5016 53.4757 72.2661 54.0002 71.7952 54.3668C71.5998 54.5193 71.367 54.6371 71.1021 54.7281V54.795C71.4312 54.8672 71.7203 54.985 71.9691 55.1562C72.5471 55.5737 72.8361 56.2105 72.8361 57.0642C72.8361 57.8562 72.5525 58.4985 71.9825 58.9935C71.4125 59.4886 70.6071 59.7348 69.5661 59.7348H65.0277V59.7321ZM66.5637 54.1929H69.5688C69.9969 54.1929 70.3261 54.0858 70.5562 53.8718C70.7863 53.6577 70.9041 53.3607 70.9041 52.978C70.9041 52.6408 70.789 52.3679 70.5562 52.1645C70.3234 51.9611 69.9943 51.8568 69.5688 51.8568H66.5637V54.1929V54.1929ZM66.5637 58.263H69.5019C70.6579 58.263 71.2386 57.8188 71.2386 56.9277C71.2386 56.0366 70.6606 55.5924 69.5019 55.5924H66.5637V58.263V58.263Z'
							fill='white'
						/>
						<path
							d='M77.0437 59.7321L79.4467 50.3877H83.2519L85.7218 59.7321H84.1189L83.4793 57.3291H79.1818L78.5824 59.7321H77.0437V59.7321ZM79.5805 55.86H83.1047L82.0504 51.8541H80.5813L79.5805 55.86Z'
							fill='white'
						/>
						<path
							d='M87.6324 58.5965C86.6717 57.6626 86.1901 56.4825 86.1901 55.0589C86.1901 53.6353 86.6717 52.4552 87.6324 51.5213C88.5931 50.5874 89.7812 50.1191 91.1967 50.1191C92.8157 50.1191 94.0814 50.6891 94.9886 51.8291C95.3899 52.3188 95.6816 52.8834 95.8689 53.5256H94.3329C94.1724 53.1697 93.9503 52.8486 93.6666 52.565C93.0164 51.9147 92.1922 51.5909 91.1967 51.5909C90.1906 51.5909 89.3718 51.912 88.7402 52.5516C88.1087 53.1911 87.7929 54.0287 87.7929 55.0616C87.7929 56.0945 88.1087 56.9294 88.7402 57.5716C89.3718 58.2139 90.1906 58.5323 91.1967 58.5323C92.219 58.5323 93.0619 58.1764 93.7202 57.4646C94.0145 57.1622 94.242 56.8063 94.3998 56.3969H95.9358C95.7057 57.09 95.3792 57.6921 94.9618 58.2005C94.0359 59.402 92.7809 60.0014 91.1967 60.0014C89.7812 59.9987 88.5931 59.5304 87.6324 58.5965Z'
							fill='white'
						/>
						<path
							d='M96.3367 59.7321L98.7396 50.3877H102.545L105.015 59.7321H103.412L102.772 57.3291H98.4747L97.8753 59.7321H96.3367V59.7321ZM98.8734 55.86H102.398L101.343 51.8541H99.8742L98.8734 55.86Z'
							fill='white'
						/>
						<path
							d='M106.149 59.7321V50.3877H109.285C110.736 50.3877 111.932 50.8373 112.876 51.7364C113.821 52.6355 114.292 53.7433 114.292 55.0599C114.292 56.3765 113.821 57.4843 112.876 58.3834C111.932 59.2825 110.736 59.7321 109.285 59.7321H106.149V59.7321ZM107.685 58.263H109.288C110.31 58.263 111.134 57.966 111.758 57.3692C112.381 56.7725 112.692 56.0045 112.692 55.0599C112.692 54.1153 112.379 53.3473 111.758 52.7506C111.134 52.1538 110.313 51.8568 109.288 51.8568H107.685V58.263Z'
							fill='white'
						/>
						<path
							d='M115.761 59.7321V50.3877H122.505V51.8568H117.297V54.2598H121.504V55.7289H117.297V58.2657H122.505V59.7348H115.761V59.7321Z'
							fill='white'
						/>
						<path
							d='M123.904 59.7321V50.3877H126.441L128.978 56.1945H129.312L131.849 50.3877H134.319V59.7321H132.783V53.1894L132.917 51.921H132.783L130.313 57.594H127.91L125.44 51.921H125.307L125.44 53.1894V59.7321H123.904V59.7321Z'
							fill='white'
						/>
						<path
							d='M135.252 50.3877H137.055L139.525 54.5274H139.726L142.196 50.3877H143.999L140.395 56.2614V59.7321H138.859V56.2614L135.252 50.3877Z'
							fill='white'
						/>
					</svg>
				</div>
				<div className='footer__contact'>
					<div className='soc__media'>
						<a href='https://www.instagram.com/owa.uz/' rel='noreferrer'>
							<svg
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_23_35)'>
									<path
										d='M13.0325 0H4.96724C2.22831 0 0 2.22841 0 4.96735V13.0327C0 15.7717 2.22831 18 4.96724 18H13.0325C15.7717 18 18 15.7716 18 13.0327V4.96735C18.0001 2.22841 15.7717 0 13.0325 0ZM16.4031 13.0327C16.4031 14.8911 14.8911 16.403 13.0327 16.403H4.96724C3.10892 16.4031 1.59705 14.8911 1.59705 13.0327V4.96735C1.59705 3.10902 3.10892 1.59705 4.96724 1.59705H13.0325C14.891 1.59705 16.403 3.10902 16.403 4.96735V13.0327H16.4031Z'
										fill='white'
									/>
									<path
										d='M8.99948 4.31055C6.44197 4.31055 4.36133 6.39118 4.36133 8.9487C4.36133 11.5061 6.44197 13.5867 8.99948 13.5867C11.557 13.5867 13.6376 11.5061 13.6376 8.9487C13.6376 6.39118 11.557 4.31055 8.99948 4.31055ZM8.99948 11.9895C7.32269 11.9895 5.95838 10.6254 5.95838 8.94859C5.95838 7.27169 7.32258 5.90749 8.99948 5.90749C10.6763 5.90749 12.0405 7.27169 12.0405 8.94859C12.0405 10.6254 10.6762 11.9895 8.99948 11.9895Z'
										fill='white'
									/>
									<path
										d='M13.8314 2.95508C13.5237 2.95508 13.2214 3.07965 13.0041 3.29791C12.7858 3.51511 12.6602 3.81748 12.6602 4.12624C12.6602 4.43405 12.7859 4.73632 13.0041 4.95458C13.2213 5.17178 13.5237 5.29741 13.8314 5.29741C14.1402 5.29741 14.4415 5.17178 14.6597 4.95458C14.878 4.73632 15.0026 4.43394 15.0026 4.12624C15.0026 3.81748 14.878 3.51511 14.6597 3.29791C14.4425 3.07965 14.1402 2.95508 13.8314 2.95508Z'
										fill='white'
									/>
								</g>
								<defs>
									<clipPath id='clip0_23_35'>
										<rect width='18' height='18' fill='white' />
									</clipPath>
								</defs>
							</svg>
						</a>
						<a href='https://www.facebook.com/owa.uz' rel='noreferrer'>
							<svg
								width='18'
								height='18'
								viewBox='0 0 18 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_23_39)'>
									<path
										d='M6.81034 3.47584C6.81034 3.92812 6.81034 5.94685 6.81034 5.94685H5V8.9684H6.81034V17.9474H10.5292V8.96865H13.0247C13.0247 8.96865 13.2584 7.51984 13.3717 5.9357C13.0469 5.9357 10.5432 5.9357 10.5432 5.9357C10.5432 5.9357 10.5432 4.17786 10.5432 3.86975C10.5432 3.56096 10.9487 3.14561 11.3494 3.14561C11.7494 3.14561 12.5937 3.14561 13.3756 3.14561C13.3756 2.73422 13.3756 1.31278 13.3756 1.07935e-08C12.3317 1.07935e-08 11.1442 1.07935e-08 10.6207 1.07935e-08C6.71842 -0.000208626 6.81034 3.02435 6.81034 3.47584Z'
										fill='white'
									/>
								</g>
								<defs>
									<clipPath id='clip0_23_39'>
										<rect width='18' height='18' fill='white' />
									</clipPath>
								</defs>
							</svg>
						</a>
						<a href='https://t.me/owauz' rel='noreferrer'>
							<svg
								width='20'
								height='20'
								viewBox='0 0 18 18'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<g clipPath='url(#clip0_1_4)'>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M9 0C4.02975 0 0 4.02975 0 9C0 13.9702 4.02975 18 9 18C13.9702 18 18 13.9702 18 9C18 4.02975 13.9702 0 9 0ZM9 1.5C13.1355 1.5 16.5 4.8645 16.5 9C16.5 13.1355 13.1355 16.5 9 16.5C4.8645 16.5 1.5 13.1355 1.5 9C1.5 4.8645 4.8645 1.5 9 1.5ZM11.019 12.6667C11.1398 12.753 11.295 12.774 11.4337 12.7215C11.5725 12.669 11.6753 12.5505 11.7053 12.4065C12.0315 10.875 12.822 6.99825 13.1182 5.6055C13.1407 5.5005 13.104 5.39175 13.0215 5.32125C12.939 5.2515 12.8242 5.23125 12.7222 5.26875C11.1502 5.85075 6.3075 7.66725 4.32825 8.4C4.20225 8.4465 4.1205 8.56725 4.125 8.7C4.1295 8.83275 4.21875 8.9475 4.347 8.98575C5.235 9.25125 6.4005 9.621 6.4005 9.621C6.4005 9.621 6.94425 11.2657 7.2285 12.102C7.26375 12.2062 7.34625 12.2895 7.45425 12.3173C7.563 12.3458 7.67775 12.3165 7.75875 12.24C8.21475 11.8095 8.91975 11.1443 8.91975 11.1443C8.91975 11.1443 10.2593 12.126 11.019 12.6667V12.6667ZM6.89025 9.41325L7.52025 11.49L7.65975 10.1745C7.65975 10.1745 10.092 7.98075 11.4788 6.72975C11.52 6.69375 11.5252 6.63225 11.4915 6.58875C11.4577 6.546 11.397 6.5355 11.3505 6.56475C9.74325 7.59075 6.89025 9.41325 6.89025 9.41325V9.41325Z'
										fill='white'
									/>
								</g>
								<defs>
									<clipPath id='clip0_1_4'>
										<rect width='20' height='20' fill='white' />
									</clipPath>
								</defs>
							</svg>
						</a>
					</div>
					<div className='contact'>
						<span className='tel'>+998(75) 220 01 07</span>
					</div>
				</div>
			</section>
		</footer>
	);
}
